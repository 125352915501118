var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CModalExtended',{attrs:{"title":_vm.modalTitle,"color":"dark","size":"lg","close-on-backdrop":false,"show":_vm.modalActive},on:{"update:show":function($event){_vm.modalActive=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"add","shape":"square","disabled":_vm.isSubmit},on:{"click":_vm.submit}},[(!_vm.isSubmit)?_c('div',[_c('CIcon',{attrs:{"name":"checkAlt"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("button.accept")))])],1):_vm._e(),(_vm.isSubmit)?_c('div',[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("button.accept")))])]):_vm._e()]),_c('CButton',{attrs:{"color":"wipe","shape":"square","disabled":_vm.isSubmit},on:{"click":function($event){return _vm.toggle(false)}}},[_c('CIcon',{attrs:{"name":"x"}}),_vm._v("  "+_vm._s(_vm.$t("button.cancel"))+" ")],1)]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CSelect',{attrs:{"placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":_vm.$t('label.type'),"horizontal":{
          label: 'col-sm-12 col-lg-2',
          input: 'col-sm-12 col-lg-10',
        },"options":_vm.cancelReasonsOptions,"disabled":_vm.isEmpty(_vm.transactionReason),"value":_vm.$v.reversePosition.CancelTransacReasonId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.reversePosition.CancelTransacReasonId),"is-valid":_vm.hasError(_vm.$v.reversePosition.CancelTransacReasonId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.reversePosition.CancelTransacReasonId, "$model", $event)}}})],1)],1),_c('CCollapse',{attrs:{"show":_vm.collapse == 1 && (_vm.visit == 'discharge' || _vm.visit == 'restow')}},[_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":'BAY',"horizontal":{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          },"options":_vm.baysOptions,"disabled":_vm.isEmpty(_vm.bays),"value":_vm.$v.bay.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.bay),"is-valid":_vm.hasError(_vm.$v.bay)},on:{"update:value":function($event){return _vm.$set(_vm.$v.bay, "$model", $event)}}}),_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.baysLoading),expression:"baysLoading"}],attrs:{"element":"select"}})],1),_c('CCol',{attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":_vm.$t('label.positionContainer'),"horizontal":{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          },"options":_vm.baysPositionsOptions,"disabled":_vm.isEmpty(_vm.baysPositions),"value":_vm.$v.reversePosition.VesselBayPosId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.reversePosition.VesselBayPosId),"is-valid":_vm.hasError(_vm.$v.reversePosition.VesselBayPosId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.reversePosition.VesselBayPosId, "$model", $event)}}})],1)],1)],1),_c('CCollapse',{attrs:{"show":_vm.collapse == 2}},[_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":_vm.visit == 'restow'? 12 : 6}},[_c('CSelect',{attrs:{"placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":_vm.$t('label.crane'),"horizontal":{
            label: _vm.visit == 'restow'? 'col-sm-12 col-lg-2' : 'col-sm-12 col-lg-4',
            input: _vm.visit == 'restow'? 'col-sm-12 col-lg-10'  : 'col-sm-12 col-lg-8',
          },"options":_vm.cranesOptions,"disabled":_vm.isEmpty(_vm.cranes),"value":_vm.$v.reversePosition.VisitCraneId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.reversePosition.VisitCraneId),"is-valid":_vm.hasError(_vm.$v.reversePosition.VisitCraneId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.reversePosition.VisitCraneId, "$model", $event)}}})],1),(_vm.visit != 'restow')?_c('CCol',{attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":_vm.$t('label.motive'),"horizontal":{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          },"options":_vm.reasonsOptions,"disabled":_vm.isEmpty(_vm.reasons),"value":_vm.$v.reversePosition.MovStowageReasonId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.reversePosition.MovStowageReasonId),"is-valid":_vm.hasError(_vm.$v.reversePosition.MovStowageReasonId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.reversePosition.MovStowageReasonId, "$model", $event)}}})],1):_vm._e(),(_vm.visit != 'load')?_c('CCol',{staticClass:"mt-2",attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":'BAY',"horizontal":{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          },"options":_vm.baysOptions,"disabled":_vm.isEmpty(_vm.bays),"value":_vm.$v.bay.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.bay),"is-valid":_vm.hasError(_vm.$v.bay)},on:{"update:value":function($event){return _vm.$set(_vm.$v.bay, "$model", $event)}}}),_c('loading',{directives:[{name:"show",rawName:"v-show",value:(_vm.baysLoading),expression:"baysLoading"}],attrs:{"element":"select"}})],1):_vm._e(),(_vm.visit != 'load')?_c('CCol',{staticClass:"mt-2",attrs:{"sm":"6"}},[_c('CSelect',{attrs:{"placeholder":_vm.$t('label.select'),"addLabelClasses":"required text-right","label":_vm.$t('label.positionContainer'),"horizontal":{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          },"options":_vm.baysPositionsOptions,"disabled":_vm.isEmpty(_vm.baysPositions),"value":_vm.$v.reversePosition.VesselBayPosId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.reversePosition.VesselBayPosId),"is-valid":_vm.hasError(_vm.$v.reversePosition.VesselBayPosId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.reversePosition.VesselBayPosId, "$model", $event)}}})],1):_vm._e()],1)],1),_c('CRow',{staticClass:"mt-2"},[_c('CCol',{attrs:{"sm":"12"}},[_c('CTextarea',{attrs:{"addLabelClasses":"required text-right","label":_vm.$t('label.observation'),"placeholder":_vm.$t('label.observation'),"horizontal":{
          label: 'col-sm-12 col-lg-2',
          input: 'col-sm-12 col-lg-10',
        },"rows":"3","maxlength":"150","invalid-feedback":_vm.errorMessage(_vm.$v.reversePosition.Observation),"is-valid":_vm.hasError(_vm.$v.reversePosition.Observation)},model:{value:(_vm.$v.reversePosition.Observation.$model),callback:function ($$v) {_vm.$set(_vm.$v.reversePosition.Observation, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.reversePosition.Observation.$model"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }