var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CModalExtended',{staticClass:"modal-extended",attrs:{"title":_vm.modalTitle,"color":"dark","size":"lg","close-on-backdrop":false,"show":_vm.modalActive},on:{"update:show":function($event){_vm.modalActive=$event}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('CButton',{attrs:{"color":"add","shape":"square","disabled":_vm.isSubmit},on:{"click":_vm.submit}},[(!_vm.isSubmit)?_c('div',[_c('CIcon',{attrs:{"name":"checkAlt"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("button.accept")))])],1):_vm._e(),(_vm.isSubmit)?_c('div',[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_c('span',{staticClass:"ml-1"},[_vm._v(_vm._s(_vm.$t("button.accept")))])]):_vm._e()]),_c('CButton',{attrs:{"color":"wipe","shape":"square","disabled":_vm.isSubmit},on:{"click":function($event){return _vm.toggle(false)}}},[_c('CIcon',{attrs:{"name":"x"}}),_vm._v("  "+_vm._s(_vm.$t("button.cancel"))+" ")],1)]},proxy:true}])},[_c('CRow',[_c('CCol',{attrs:{"sm":"12"}},[_c('CInput',{attrs:{"value":_vm.getMovName,"horizontal":{
          label: 'col-sm-12 col-lg-4',
          input: 'col-sm-12 col-lg-8',
        },"label":_vm.$t('label.movementType'),"addLabelClasses":"text-right","maxlength":"100","disabled":""}}),_c('CInput',{staticClass:"mt-2",attrs:{"value":_vm.getEquipmentQty,"horizontal":{
          label: 'col-sm-12 col-lg-4',
          input: 'col-sm-12 col-lg-8',
        },"label":_vm.$t('label.equipmentQuantity'),"addLabelClasses":"text-right","maxlength":"100","disabled":""}}),_c('CSelect',{staticClass:"mt-2",attrs:{"label":_vm.$t('label.motive'),"placeholder":_vm.$t('label.select'),"horizontal":{
          label: 'col-sm-12 col-lg-4',
          input: 'col-sm-12 col-lg-8',
        },"addLabelClasses":"required text-right","disabled":_vm.isEmpty(_vm.reasons),"options":_vm.reasonsOptions,"value":_vm.$v.cancelInfo.MovStowageReasonId.$model,"invalid-feedback":_vm.errorMessage(_vm.$v.cancelInfo.MovStowageReasonId),"is-valid":_vm.hasError(_vm.$v.cancelInfo.MovStowageReasonId)},on:{"update:value":function($event){return _vm.$set(_vm.$v.cancelInfo.MovStowageReasonId, "$model", $event)}}}),_c('div',{staticClass:"d-flex mt-2"},[_c('label',{staticClass:"col-sm-12 col-lg-4 col-xl-4 required text-right mt-2 pr-1"},[_vm._v(_vm._s(_vm.$t('label.date')))]),_c('CCol',{staticClass:"p-0",attrs:{"sm":"12","lg":"8","xl":"8"}},[_c('vue-datepicker',{attrs:{"type":"datetime","header":"","lang":this.$i18n.locale,"editable":false,"clearable":false,"format":"DD/MM/YYYY HH:mm","placeholder":"DD/MM/YYYY HH:mm","time-title-format":"DD/MM/YYYY HH:mm","disabled-date":_vm.validateDateRange,"append-to-body":false,"disabled":false,"value-type":"format","show-second":false},scopedSlots:_vm._u([{key:"icon-calendar",fn:function(){return [_c('div',{staticStyle:{"display":"none"}})]},proxy:true}]),model:{value:(_vm.cancelInfo.TransactionDate),callback:function ($$v) {_vm.$set(_vm.cancelInfo, "TransactionDate", $$v)},expression:"cancelInfo.TransactionDate"}})],1)],1),_c('CTextarea',{staticClass:"mt-2",attrs:{"addLabelClasses":"required text-right","label":_vm.$t('label.observation'),"placeholder":_vm.$t('label.observation'),"horizontal":{
          label: 'col-sm-12 col-lg-4',
          input: 'col-sm-12 col-lg-8',
        },"rows":"3","maxlength":"150","invalid-feedback":_vm.errorMessage(_vm.$v.cancelInfo.MovStowageReasonDs),"is-valid":_vm.hasError(_vm.$v.cancelInfo.MovStowageReasonDs)},model:{value:(_vm.$v.cancelInfo.MovStowageReasonDs.$model),callback:function ($$v) {_vm.$set(_vm.$v.cancelInfo.MovStowageReasonDs, "$model", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"$v.cancelInfo.MovStowageReasonDs.$model"}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }