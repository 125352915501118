<template>
    <CRow style="margin-top: 20px">
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg" style="display:none" preserveAspectRatio="xMidYMid meet">
            <symbol id="icon-discrepancia" fill="currentColor" viewBox="0 0 511.000000 512.000000">
                <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" stroke="none">
                    <path d="M561 4899 c-24 -4 -48 -12 -55 -17 -6 -5 -24 -12 -41 -16 -16 -4 -51
                    -23 -77 -42 -87 -64 -147 -161 -167 -269 -14 -76 -15 -2403 -1 -2488 21 -133
                    116 -260 239 -320 l66 -32 273 -3 272 -3 0 210 0 211 -215 0 -215 0 2 1173 3
                    1172 1167 3 1168 2 2 -212 3 -213 210 0 210 0 3 210 c4 322 -12 390 -124 506
                    -53 54 -82 75 -143 101 l-76 33 -1230 2 c-676 0 -1250 -3 -1274 -8z"/>
                    <path d="M1830 3616 c-171 -39 -297 -169 -329 -341 -6 -32 -11 -159 -11 -282
                    l0 -223 213 2 212 3 3 212 2 213 213 2 212 3 0 210 0 210 -230 2 c-153 1 -248
                    -3 -285 -11z"/>
                    <path d="M2771 3616 c-8 -9 -11 -74 -9 -212 l3 -199 107 -3 107 -3 3 -102 3
                    -102 208 -3 207 -2 0 320 0 320 -309 0 c-250 0 -311 -3 -320 -14z"/>
                    <path d="M3834 3617 c-2 -7 -3 -102 -2 -212 l3 -200 213 -3 212 -2 0 -1175 0
                    -1175 -1170 0 -1170 0 -2 213 -3 212 -212 3 -213 2 0 -217 c0 -245 7 -307 46
                    -393 33 -74 125 -165 209 -206 l60 -29 1280 0 1280 0 67 32 c94 44 160 109
                    206 202 l37 75 0 1285 0 1286 -32 66 c-60 123 -187 218 -320 239 -92 15 -483
                    13 -489 -3z"/>
                    <path d="M2987 2553 c-4 -3 -7 -100 -7 -215 l0 -208 -210 0 -210 0 0 -211 0
                    -210 263 3 262 3 68 32 c89 43 169 119 210 202 l32 65 3 273 3 273 -204 0
                    c-112 0 -207 -3 -210 -7z"/>
                    <path d="M1497 2344 c-4 -4 -7 -148 -7 -321 l0 -313 318 2 317 3 0 205 0 205
                    -102 3 -102 3 -3 107 -3 107 -206 3 c-113 1 -208 -1 -212 -4z"/>
                </g>
            </symbol>
        </svg>
        <CCol sm="12">
            <CRow class="mb-3 d-flex align-items-center">
                <CCol sm="7" >
                    <CRow>
                        <CCol sm="6" >
                            <CSelect
                                :label="$t('label.typeOfFile')"
                                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                addLabelClasses="text-right"
                                :options="[]"
                                :invalid-feedback="$t('label.required')"
                            />
                        </CCol>
                        <CCol sm="5" >
                            <CSelect
                                :label="$t('label.menu_diary.CRANES')"
                                :horizontal="{ label: 'col-sm-5', input: 'col-sm-7'}"
                                addLabelClasses="text-right"
                                :options="[]"
                                :invalid-feedback="$t('label.required')"
                            />
                        </CCol>
                        <CCol sm="1" >
                            <CButton
                                color="info"
                                size="sm"
                                class="mr-1"
                                v-c-tooltip="{
                                    content:  $t('label.DownloadFormat'),
                                    placement: 'top-end',
                                }"
                                target="_blank"
                            >
                                <CIcon name="cil-document"/>
                            </CButton>
                        </CCol>
                    </CRow>
                </CCol>
                <CCol sm="5" class="d-flex align-items-center justify-content-end">
                    <h6 class="text-light-orange mr-3 mt-2"><svg class="bi flex-shrink-0 me-2" width="18" height="18" role="img" aria-label="Danger:"><use xlink:href="#icon-discrepancia"/></svg>&nbsp;{{$t('label.Discrepancies')}}&nbsp;</h6> 
                    <h6 class="text-danger mr-3 mt-2"><CIcon name='errores' color="wipe" size='lg' class="titulo-icono" />{{$t('label.errors')}}&nbsp;</h6> 
                    <h6 class="text-warning mr-3 mt-2"><CIcon name='alertas' color="wipe" size='lg' class="titulo-icono" />{{$t('label.alerts')}}&nbsp;</h6> 
                    <CButton
                        color="excel" class="mr-1" size="sm"
                        @click="onBtnExport(true)"
                        >
                            <CIcon name="cil-file-excel"/>&nbsp; XSLX
                    </CButton>
                    <CButton
                        color="watch" size="sm"
                        @click="onBtnExport(false)"
                    >
                        <CIcon name="cil-file-excel"/>&nbsp; CSV
                    </CButton>
                </CCol>
            </CRow>
            <CRow class="mb-3 d-flex align-items-center">
                <CCol sm="12">
                    <CTabs variant="tabs" :active-tab="0">
                        <CTab :title="$t('label.UploadFile')"  class="form-especial">
                            <CCard bodyWrapper>
                                <div class="container-fluid">
                                    <CRow class="mb-3" style="margin-top: 10px">
                                        <CCol sm="11">
                                            <vue-dropzone 
                                                ref="myVueDropzone" 
                                                id="dropzone" 
                                                :options="dropzoneOptions" 
                                                :useCustomSlot="true"
                                                v-on:vdropzone-file-added="sendingEvent"
                                                v-on:vdropzone-files-added="sendingEvent"
                                            > 
                                                <div class="dropzone-custom-content">
                                                    <h3 class="dropzone-custom-title"><CIcon name='cil-cloud-upload'/> ¡{{$t('label.dragAndDropToUploadContent')}}!</h3>
                                                    <div class="subtitle">...{{$t('label.orClickToSelectFileFromYourComputer')}} </div>
                                                </div>
                                            </vue-dropzone>
                                        </CCol>
                                        <CCol sm="1" class="d-flex align-items-end justify-content-start">
                                            <CButton 
                                                color="add"
                                                size="sm"
                                                v-c-tooltip="{
                                                    content: $t('label.add'),
                                                    placement: 'top-end'
                                                }"
                                            >
                                                <CIcon name="checkAlt"/>
                                            </CButton>
                                        </CCol>
                                        <CCol sm="12">
                                            <dataTableExtended
                                                class="align-center-row-datatable"
                                                hover
                                                sorter
                                                small
                                                :loading="apiStateLoading"
                                                column-filter
                                                :table-filter="tableTextHelpers.tableFilterText"
                                                :items-per-page-select="tableTextHelpers.itemsPerPageText"
                                                :items="dateFilteredItems"
                                                :fields="fields"
                                                :noItemsView="tableTextHelpers.noItemsViewText"
                                                :items-per-page="5"
                                                @row-clicked="itemChanged"
                                                pagination
                                            >
                                                <template #RowNumber="{index}">
                                                    <td class="center-cell text-center">
                                                        {{index+1}}
                                                    </td>
                                                </template>
                                                <template #ErrorJson="{item}">
                                                    <td>
                                                        <div class="animation-item" v-if="item.ErrorJson.length !== 0">
                                                            <h3 class="text-danger"><svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-square-fill"/></svg></h3>
                                                        </div>
                                                        <div class="animation-item" v-if="item.ErrorJson.length === 0">
                                                            <h3 class="text-success"><svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Success:"><use xlink:href="#check-square-fill"/></svg></h3>
                                                        </div>
                                                    </td>
                                                </template>
                                            </dataTableExtended>
                                        </CCol>
                                        <CCol sm="12" class="d-flex align-items-center justify-content-end">
                                            <CButton
                                                color="add"
                                                size="sm"
                                                class="mr-1"
                                                target="_blank"
                                            >
                                                <CIcon name="checkAlt" /> &nbsp; {{ $t('button.confirm') }}
                                            </CButton>
                                        </CCol>
                                    </CRow>
                                </div>
                            </CCard>
                        </CTab>
                    </CTabs>
                </CCol>
            </CRow>
        </CCol>
    </CRow>
</template>

<script>
    import ENUM from '@/_store/enum';
    import { mapState } from 'vuex';
    import vue2Dropzone from 'vue2-dropzone'
    import 'vue2-dropzone/dist/vue2Dropzone.min.css'

    let user = JSON.parse(localStorage.getItem('user'));
    //data
    function data() {
        return {
            dropzoneOptions: {
                url: `${process.env.VUE_APP_API_BASE}files/`,
                autoProcessQueue: false,
                maxFiles: 1,
                addRemoveLinks: true,
                dictRemoveFile: `${this.$t('label.delete')}`,
                dictCancelUpload: `${this.$t('label.cancelUpload')}`,
                maxfilesexceeded (files) {
                    this.removeAllFiles();
                    this.addFile(files);
                },
                acceptedFiles: '.pdf, .doc, .docx, .xlsx',
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${user.token}`
                }
            },
        }
    }
    //methods
    function onBtnExport(value){

    }
    function itemChanged(item, index, column, event){
        if(item.ErrorJson.length !== 0){
            this.items = item;
        }
    }
    function sendingEvent(file){
        this.$refs.myVueDropzone.removeFile(file);
    }
    //computeds
    function apiStateLoading() {
        let carga = false;
        if(this.apiStateForm === ENUM.LOADING){
            carga = !carga;
        }
        return carga;
    }
    function fields(){
        return [
            { key: 'RowNumber', label: '#',filter :false, _style:'width:1%; text-align: center;' },
            { key: 'ContainerCode', label: this.$t('label.containerCode'),_classes:"center-cell text-left", _style:'min-width:150px;text-align: left;'},
            { key: 'Size', label: this.$t('label.size')+' (FT)',_classes:"center-cell"},
            { key: 'IsoCode', label: this.$t('label.isoCode'),_classes:"center-cell"},
            { key: 'Status', label: this.$t('label.status'),_classes:"center-cell"},
            { key: 'Seals', label: this.$t('label.Seal')+'s',_classes:"center-cell"},
            { key: 'ShippingLineCode', label: this.$t('label.carrier'),_classes:"center-cell"},
            { key: 'ImdgCode', label: 'IMDG',_classes:"center-cell"},
            { key: 'LoadPort', label: 'POL',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'DischargePort', label: 'POD',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'DeliveryPort', label: 'PODEL',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'TranshipmentPort', label: 'POT',_classes:"center-cell", _style:'min-width:150px;'},
            { key: 'Weigth', label: this.$t('label.weight')+' (KGM)',_classes:"center-cell"},
            { key: 'Vgm', label: 'VGM (KGM)',_classes:"center-cell"},
            { key: 'VgmCertificate', label: this.$t('label.certificateVGM'),_classes:"center-cell text-left", _style:'min-width:150px;text-align: left;'},
            { 
                key: 'ErrorJson',
                _style:'width:50px;text-align: center;',
                _classes:"center-cell",
                label: '', 
                sorter: false, 
                filter: false
            }
        ]
    }
    function tableTextHelpers(){
        return {
            tableFilterText: {
                label: this.$t('label.filter'),
                placeholder: this.$t('label.enterSearch'),
            },
            itemsPerPageText: {
                label: this.$t('label.recordsPerPage'),
            },
            noItemsViewText: {
                noResults: this.$t('label.noResultsFound'),
                noItems: this.$t('label.noRecordsAvailble'),
            },
        }
    }
    function dateFilteredItems(){
        return [];
    }
    export default {
        name:'manual-lists',
        data,
        components: {
            vueDropzone: vue2Dropzone
        },
        methods:{
            onBtnExport,
            sendingEvent,
            itemChanged
        },
        computed:{
            fields,
            tableTextHelpers,
            dateFilteredItems,
            apiStateLoading,
            ...mapState({
                user: state => state.auth.user,
                VisitId: state => state.visitas.VisitId,
                apiStateForm: (state) => state.visitas.apiStateForm,
            })
        }
    }
</script>

<style lang="scss" scoped>
</style>