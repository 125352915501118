import {
    required,
    helpers,
    minLength,
    maxLength,
  } from 'vuelidate/lib/validators';
  
  import { textareaSpecials } from '../funciones';
  import { DateFormater } from "@/_helpers/funciones";
  
  const alphaNumCustom = (value) => !helpers.req(value) || /^[\w\u00f1\u00d1\-]+$/g.test(value);
  const TextareaNotReq = (value) => !helpers.req(value) || textareaSpecials(value);

  export default ( PreviousDate, LaterDate) => {
    return {
      bayPosition: {
        VisitCargoId: {},
        VisitId: {},
        VisitCraneId: { required },
        Seal1: { alphaNumCustom },
        Seal2: { alphaNumCustom },
        Seal3: { alphaNumCustom },
        Seal4: { alphaNumCustom },
        Observation: { TextareaNotReq },
        TransactionDate: { required, RangeDate(value){
            return( DateFormater.formatDateTimeWithoutSlash(value) >  DateFormater.formatDateTimeWithoutSlash(PreviousDate) && DateFormater.formatDateTimeWithoutSlash(value) <= DateFormater.formatDateTimeWithoutSlash(LaterDate))
          } 
        },
      },
    }
  }

  export const ConfirmLoad = ( PreviousDate, LaterDate) =>  {
    return {
      bayPosition: {
        VisitCargoId: {},
        VisitId: {},
        VisitCraneId: { },
        Seal1: { alphaNumCustom },
        Seal2: { alphaNumCustom },
        Seal3: { alphaNumCustom },
        Seal4: { alphaNumCustom },
        Observation: { TextareaNotReq },
        TransactionDate: { required, RangeDate(value){
          return( DateFormater.formatDateTimeWithoutSlash(value) >  DateFormater.formatDateTimeWithoutSlash(PreviousDate) && DateFormater.formatDateTimeWithoutSlash(value) <= DateFormater.formatDateTimeWithoutSlash(LaterDate))
          } 
        },
      },
    }
  }

  export const ConfirmRestow = ( PreviousDate, LaterDate ) =>  {
    return {
      bayPosition: {
        VisitCargoId: {},
        VisitCraneId: { },
        VesselBayPosId: { },
        MovStowageReasonId: { required },
        MovStowageReasonDs: { textareaSpecials },
        TransactionDate: { required, RangeDate(value){
          return( DateFormater.formatDateTimeWithoutSlash(value) >  DateFormater.formatDateTimeWithoutSlash(PreviousDate) && DateFormater.formatDateTimeWithoutSlash(value) <= DateFormater.formatDateTimeWithoutSlash(LaterDate))
          } 
        },
        //No needed
        VisitId: {},
        VisitCraneId: { },
        Seal1: { },
        Seal2: { },
        Seal3: { },
        Seal4: { },
        Observation: { },
      },
    }
  }