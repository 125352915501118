<template>
  <CModalExtended :title="modalTitle" color="dark" size="lg" :close-on-backdrop="false" :show.sync="modalActive">
    <CRow>
      <CCol sm="12">
        <CSelect
          :placeholder="$t('label.select')"
          addLabelClasses="required text-right"
          :label="$t('label.type')"
          :horizontal="{
            label: 'col-sm-12 col-lg-2',
            input: 'col-sm-12 col-lg-10',
          }"
          :options="cancelReasonsOptions"
          :disabled="isEmpty(transactionReason)"
          :value.sync="$v.reversePosition.CancelTransacReasonId.$model"
          :invalid-feedback="errorMessage($v.reversePosition.CancelTransacReasonId)"
          :is-valid="hasError($v.reversePosition.CancelTransacReasonId)"
        >
        </CSelect>
      </CCol>    
    </CRow>
    <CCollapse :show="collapse == 1 && (visit == 'discharge' || visit == 'restow')">
      <CRow class="mt-2">
        <!-- Bay - Position -->
        <CCol sm="6">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="'BAY'"
            :horizontal="{
              label: 'col-sm-12 col-lg-4',
              input: 'col-sm-12 col-lg-8',
            }"
            :options="baysOptions"
            :disabled="isEmpty(bays)"
            :value.sync="$v.bay.$model"
            :invalid-feedback="errorMessage($v.bay)"
            :is-valid="hasError($v.bay)"
          >
          </CSelect>
          <loading v-show="baysLoading" element="select" />
        </CCol>
        <CCol sm="6">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.positionContainer')"
            :horizontal="{
              label: 'col-sm-12 col-lg-4',
              input: 'col-sm-12 col-lg-8',
            }"
            :options="baysPositionsOptions"
            :disabled="isEmpty(baysPositions)"
            :value.sync="$v.reversePosition.VesselBayPosId.$model"
            :invalid-feedback="errorMessage($v.reversePosition.VesselBayPosId)"
            :is-valid="hasError($v.reversePosition.VesselBayPosId)"
          >
          </CSelect>
        </CCol>
      </CRow>
    </CCollapse>
    <CCollapse :show="collapse == 2">
      <CRow class="mt-2">
        <!-- Crane - Mov -->
        <CCol :sm="visit == 'restow'? 12 : 6">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.crane')"
            :horizontal="{
              label: visit == 'restow'? 'col-sm-12 col-lg-2' : 'col-sm-12 col-lg-4',
              input: visit == 'restow'? 'col-sm-12 col-lg-10'  : 'col-sm-12 col-lg-8',
            }"
            :options="cranesOptions"
            :disabled="isEmpty(cranes)"
            :value.sync="$v.reversePosition.VisitCraneId.$model"
            :invalid-feedback="errorMessage($v.reversePosition.VisitCraneId)"
            :is-valid="hasError($v.reversePosition.VisitCraneId)"
          >
          </CSelect>
        </CCol>
        <CCol sm="6" v-if="visit != 'restow'">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.motive')"
            :horizontal="{
              label: 'col-sm-12 col-lg-4',
              input: 'col-sm-12 col-lg-8',
            }"
            :options="reasonsOptions"
            :disabled="isEmpty(reasons)"
            :value.sync="$v.reversePosition.MovStowageReasonId.$model"
            :invalid-feedback="errorMessage($v.reversePosition.MovStowageReasonId)"
            :is-valid="hasError($v.reversePosition.MovStowageReasonId)"
          >
          </CSelect>
        </CCol>
  
        <CCol sm="6" class="mt-2" v-if="visit != 'load'">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="'BAY'"
            :horizontal="{
              label: 'col-sm-12 col-lg-4',
              input: 'col-sm-12 col-lg-8',
            }"
            :options="baysOptions"
            :disabled="isEmpty(bays)"
            :value.sync="$v.bay.$model"
            :invalid-feedback="errorMessage($v.bay)"
            :is-valid="hasError($v.bay)"
          >
          </CSelect>
          <loading v-show="baysLoading" element="select" />
        </CCol>
        <CCol sm="6" class="mt-2" v-if="visit != 'load'">
          <CSelect
            :placeholder="$t('label.select')"
            addLabelClasses="required text-right"
            :label="$t('label.positionContainer')"
            :horizontal="{
              label: 'col-sm-12 col-lg-4',
              input: 'col-sm-12 col-lg-8',
            }"
            :options="baysPositionsOptions"
            :disabled="isEmpty(baysPositions)"
            :value.sync="$v.reversePosition.VesselBayPosId.$model"
            :invalid-feedback="errorMessage($v.reversePosition.VesselBayPosId)"
            :is-valid="hasError($v.reversePosition.VesselBayPosId)"
          >
          </CSelect>
        </CCol>
      </CRow>
    </CCollapse>
    <CRow class="mt-2">
      <CCol sm="12">
        <CTextarea
          addLabelClasses="required text-right"
          :label="$t('label.observation')"
          :placeholder="$t('label.observation')"
          :horizontal="{
            label: 'col-sm-12 col-lg-2',
            input: 'col-sm-12 col-lg-10',
          }"
          rows="3"
          maxlength="150"
          v-model.trim="$v.reversePosition.Observation.$model"
          :invalid-feedback="errorMessage($v.reversePosition.Observation)"
          :is-valid="hasError($v.reversePosition.Observation)"
        />
      </CCol> 
    </CRow>

    <template #footer>
      <CButton color="add" shape="square" :disabled="isSubmit" @click="submit">
        <div v-if="!isSubmit">
          <CIcon name="checkAlt" /><span class="ml-1">{{
              $t("button.accept")
          }}</span>
        </div>
        <div v-if="isSubmit">
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          <span class="ml-1">{{ $t("button.accept") }}</span>
        </div>
      </CButton>
      <CButton color="wipe" shape="square" :disabled="isSubmit" @click="toggle(false)">
        <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { mapState } from "vuex";
import ENUM from '@/_store/enum';
import {
  DateFormater
} from "@/_helpers/funciones";
import {UserErrorValidations, CraneValidations, UserErrorLoad, CraneLoad,  UserErrorRestow, CraneRestow} from '@/_validations/ajustes-plano/ReverseValidations';
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    reversePosition: {
      VisitCraneDetailId: "",
      VisitCraneId: "",
      CancelTransacReasonId: "",
      VesselBayPosId: "",
      MovStowageReasonId: "",
      MovStowageReasonDs: "",
      Observation: ""
    },
    collapse: 0,
    bays: [],
    bay: '',
    baysPositions: [],
    transactionReason: [],
    reasons: [],
    baysLoading: false,
    reasonsLoading: false,
  };
}

//Methods
function toggle(newVal) {
  if (newVal) {
    this.getCancelReasons();
  } else
    this.resetForm();

  this.modalActive = newVal;
}
function setData(reversePosition) {
  if (!this.visitPosition) return;

  this.reversePosition.VisitCraneDetailId = this.visitPosition.VisitCraneDetailId;
}
function resetForm() {
  let arrKeys = Object.keys(this.reversePosition);

  for (let i = 0; i < arrKeys.length; i++) {
    this.reversePosition[arrKeys[i]] = "";
  }
  this.bays = [];
  this.baysPositions = [];
  this.transactionReason = [];
  this.reasons = [];
  this.collapse = 0;
  this.bay = '';
  this.$v.$reset();
}
function clearInputs() {
  let arrKeys = Object.keys(this.reversePosition);

  for (let i = 0; i < arrKeys.length; i++) {
    if(arrKeys[i] != 'CancelTransacReasonId')
      this.reversePosition[arrKeys[i]] = "";
  }
}
function submit() {
  //if(this.visit == 'restow') return;
  try {
    this.$v.reversePosition.$touch();
    if (this.$v.reversePosition.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }

    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
    this.isSubmit = true;
    let route = "VisitCargoReverse";
    let CargoJson = {
      VisitCraneDetailId: this.visitPosition.VisitCraneDetailId ? this.visitPosition.VisitCraneDetailId : '',
      VesselCraneId: this.reversePosition.VisitCraneId,
      CancelTransacReasonId: this.reversePosition.CancelTransacReasonId,
      VesselBayPosId: this.reversePosition.VesselBayPosId,
      MovStowageReasonId: this.reversePosition.MovStowageReasonId,
      MovStowageReasonDs: this.reversePosition.MovStowageReasonDs,
      Observation: this.reversePosition.Observation,
    };

    let metodo = "POST";

    this.$http
      .ejecutar(metodo, route, CargoJson, {
        root: "CargoJson",
      })
      .then((response) => {
        this.$emit("submited", { 
          type: this.visit,
          data: response.data.data[0],
          action: 'reverse'
        });
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      })
      .then(() => {
        this.isSubmit = false;
        // this.$store.state.visitas.apiStateForm = ENUM.INIT;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}

function getCancelReasons() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  this.$http
    .get("CancelTransacReason-list", {
      Id:this.getCurrentMovId,
    })
    .then((response) => {
      this.transactionReason = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function getBays() {
  this.baysLoading = true;
  this.$http
    .get("VisitVesselBayPosBy-VisitId", {
      VisitId: this.VisitId,
      Size: this.visitPosition.Size,
    })
    .then((response) => {
      this.bays = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.baysLoading = false;
    });
}
function getBaysByCranes() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  let ruta = this.visit == 'restow'
    ? 'VisitVesselBayPosRestow-by-VesselCraneId':'VisitVesselBayPos-by-VesselCraneId';
  let params = this.visit == 'restow'
    ? {
      VisitId: this.VisitId,
      VisitCraneId: this.reversePosition.VisitCraneId,
      ContainerCode: this.visitPosition.ContainerCode,
    } : {
      VisitId: this.VisitId,
      VisitCraneId: this.reversePosition.VisitCraneId,
      Size: this.visitPosition.Size,
    };
  this.$http
    .get(ruta, params)
    .then((response) => {
      this.bays = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function getReasons() {
  this.reasonsLoading = true;
  this.$http
    .get("MovStowageReasonCancel-list", {
      MovStowageId: this.getCurrentMovId,
    })
    .then((response) => {
      this.reasons = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.reasonsLoading = false;
    });
}

//Computed
function modalTitle() {
  let title = '';
  switch (this.visit) {
    case 'discharge':
      title = this.$t("label.discharge");
      break;
    case 'load':
      title = this.$t("label.loadPortSituation");
      break;
    case 'restow':
      title = this.$t("label.restowPortSituation");
      break;
    default:
      break;
  }

  return `${this.$t('label.reverse')} ${title}: ${this.visitPosition ? this.visitPosition.ContainerCode : ""}`;
}
function cranesOptions() {
  return this.cranes.map((crane) =>
    Object.assign({}, crane, {
      label: crane.CraneAlias,
      value: crane.VisitCraneId,
    })
  );
}
function isEmpty(arr) {
  return arr.length == 0;
}
function cancelReasonsOptions() {
  return this.transactionReason.map((item) =>
    Object.assign({}, item, {
      label: this.$i18n.locale == 'en' ? item.CancelTransacReasonNameEn : item.CancelTransacReasonNameEs,
      value: item.CancelTransacReasonId,
    })
  );
}
function currentCancelReason() {
  return this.reversePosition.CancelTransacReasonId;
}
function baysOptions() {
  return this.bays.map((item) =>
    Object.assign({}, item, {
      label: item.BayCode,
      value: item.VesselBayId,
    })
  );
}
function baysPositionsOptions() {
  return this.baysPositions.map((item) =>
    Object.assign({}, item, {
      label: item.CodPosition,
      value: item.VesselBayPosId,
    })
  );
}
function getCurrentMovId() {
  switch (this.visit) {
    case 'discharge':
      return 'A13A4AE4-CDAA-4BB1-8CE6-00056BED3A8B';
    case 'load':
      return '8F8EBDB2-0AD1-4ADB-B74A-E4E7E10F469B';
    case 'restow':
      return '4ADCFC24-7850-486B-8E77-00492953E230';
  
    default:
      return '';
  }
}
function currentCrane() {
  return this.reversePosition.VisitCraneId;
}
function reasonsOptions() {
  return this.reasons.map((item) =>
    Object.assign({}, item, {
      label: this.$i18n.locale == 'en' ? item.MovStowageReasonNameEn : item.MovStowageReasonNameEs,
      value: item.MovStowageReasonId,
    })
  );
}

export default {
  name: "reverso-modal",
  mixins: [ModalMixin, General],
  props: {
    modal: Boolean,
    visit: {
      type: String,
      default: "",
    },
    visitPosition: {
      type: Object,
      default: null
    },
    cranes: {
      type: Array,
      default: () => []
    },
  },
  validations() {
    if(this.visit == 'discharge') {
      if(this.reversePosition.CancelTransacReasonId == '0DBC9BF4-B9FF-4B57-BA16-78FBF3EA7ACF')
        return UserErrorValidations();
      else
        return CraneValidations();
    } else if(this.visit == 'restow'){
      if(this.reversePosition.CancelTransacReasonId == 'CECB0A86-A225-42BD-A4D4-6716B9A27F86')
        return UserErrorRestow();
      else
        return CraneRestow();
    }else {
      if(this.reversePosition.CancelTransacReasonId == 'CECB0A86-A225-42BD-A4D4-6716B9A27F86')
        return UserErrorLoad();
      else
        return CraneLoad();
    }
  },
  data,
  methods: {
    toggle,
    setData,
    resetForm,
    isEmpty,
    submit,
    getCancelReasons,
    clearInputs,
    getBays,
    getBaysByCranes,
    getReasons,
  },
  computed: {
    modalTitle,
    cranesOptions,
    cancelReasonsOptions,
    currentCancelReason,
    baysOptions,
    baysPositionsOptions,
    currentCrane,
    reasonsOptions,
    getCurrentMovId,
    ...mapState({
      VisitId: state => state.visitas.VisitId,
      EdiFileId: state => state.visitas.EdiFileId,
      itinerarySelected: state => state.visitas.itinerarySelected,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
    currentCancelReason: function (val, old) {
      if(val) {
        if(val == '0DBC9BF4-B9FF-4B57-BA16-78FBF3EA7ACF' || val == 'CECB0A86-A225-42BD-A4D4-6716B9A27F86') {
          if(val != 'CECB0A86-A225-42BD-A4D4-6716B9A27F86')
            this.getBays();
          this.bay = '';
          this.collapse = 1;
        } else {
          this.getReasons();
          this.bay = '';
          this.bays = [];
          if(val != 'CECB0A86-A225-42BD-A4D4-6716B9A27F86')
            this.collapse = 2;
        }
  
        if(val != old)
          this.clearInputs();
      }
    },
    bay: function (val, old) {
      let found = this.bays.find((item) => item.VesselBayId == val);
      if(found)
        this.baysPositions = found.PositionJson;
      else
        this.baysPositions = [];
    },
    currentCrane: function (val, old) {
      if(val != '' && this.visit != 'load')
        this.getBaysByCranes();
    },
  },
};
</script>

<style scoped>

</style>