<template>
  <CModalExtended
    :title="modalTitle"
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <CRow>
      <CCol sm="12">
        <dataTableExtended
          class="align-center-row-datatable"
          hover
          small
          sorter
          column-filter
          :table-filter="tableText.tableFilterText"
          :items-per-page-select="tableText.itemsPerPageText"
          :noItemsView="tableText.noItemsViewText"
          :items="formatedItems"
          :fields="fields"
          :loading="isLoading"
          :items-per-page="5"
          :active-page="activePage"
          pagination
        >
        </dataTableExtended>
      </CCol>
      <CCol sm="12">
        <CTextarea
          addLabelClasses="required text-right"
          :label="$t('label.observation')"
          :placeholder="$t('label.observation')"
          rows="3"
          maxlength="150"
          v-model.trim="$v.observation.$model"
          :invalid-feedback="errorMessage($v.observation.$model)"
          :is-valid="hasError($v.observation.$model)"
        />
      </CCol>
    </CRow>
    <template #footer>
      <CButton
        color="add"
        shape="square"
        :disabled="isSubmit"
        @click="submit"
      >
        <div v-if="!isSubmit">
          <CIcon name="checkAlt" /><span class="ml-1">{{
            $t("button.accept")
          }}</span>
        </div>
        <div v-if="isSubmit">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="ml-1">{{ $t("button.accept") }}</span>
        </div>
      </CButton>
      <CButton
        color="wipe"
        shape="square"
        :disabled="isSubmit"
        @click="toggle(false)"
      >
        <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { mapState } from "vuex";
import ENUM from '@/_store/enum';
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";
import FreeValidation from '@/_validations/ajustes-plano/FreeValidation';

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    activePage: 1,
    observation: '',
    affectedList: [],
  };
}

//Methods
function toggle(newVal) {
  if(!newVal) {
    this.observation = "";
    this.affectedList = [];
  } else {
    this.getAffectedPositions();
  }

  this.modalActive = newVal;
}
function submit() {
  try {
    this.$v.$touch();
    if (this.$v.observation.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }

    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
    this.isSubmit = true;
    let route = "VisitCargoFreePosition";
    let CargoJson = this.visitList.map((item) => Object.assign({}, {
      VisitCargoId: item.VisitCargoId,
      VisitId: this.VisitId,
      Observation: this.observation,
    }));
    let metodo = "POST";

    this.$http
      .ejecutar(metodo, route, CargoJson, {
        root: "CargoJson",
      })
      .then((response) => {
        this.$emit("submited", {
          type: 'free-positions',
          bays: response.data.data[0].BayJson,
        });
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      })
      .then(() => {
        this.isSubmit = false;
        this.$store.state.visitas.apiStateForm = ENUM.INIT;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}
function getAffectedPositions() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  let CargoJson = this.visitList.map((item) => Object.assign({}, {
    VisitCargoId: item.VisitCargoId,
  }));

  this.$http
    .ejecutar('POST', 'VisitCargoPositionsJsonAffected-list', CargoJson, {
      root: "CargoJson",
    })
    .then((response) => {
      let data = response?.data?.data;
      this.affectedList = (data.length >0 && (data[0]?.Json === null)) ? [] : response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}

//Computed
function modalTitle() {
  return this.$t('label.freePositions');
}
function fields(){
  return [
    { 
      key: 'RowNumber', 
      label: '#',
      _style: 'width:1%; text-align:center;',
      filter: false
    },
    { key: 'ContainerCode', label: this.$t('label.container'), _style:'width:20%; text-align:center;'},
    { key: 'CodPosition', label: this.$t('label.positionContainer'), _style:'width:20%; text-align:center;'},
    { key: 'SizeFt', label: this.$t('label.SizeFt'), _style:'width:20%; text-align:center;'},
    { key: 'POL', label: 'POL', _style:'width:20%; text-align:center;'},
    { key: 'POD', label: 'POD', _style:'width:20%; text-align:center;'},
    { key: 'Status', label: this.$t('label.status'), _style:'width:20%; text-align:center;'}
  ];
}
function formatedItems() {
  let index = 1;
  return this.affectedList.map((item) => Object.assign({}, item, {
    RowNumber: index++,
    Status: this.$i18n.locale == "es"
    ? item.BayStatusNameEs ? item.BayStatusNameEs : 'N/A'
    : item.BayStatusNameEn ? item.BayStatusNameEn : 'N/A',
    _cellClasses: this.cellTableClasses,
  }));
}
function cellTableClasses() {
  return {
    RowNumber: "align-middle text-center",
    ContainerCode: "align-middle text-center",
    CodPosition: "align-middle text-center",
    SizeFt: "align-middle text-center",
    POL: "align-middle text-center",
    POD: "align-middle text-center",
    Status: "align-middle text-center",
  };
}

export default {
  name: "free-position-modal",
  mixins: [ModalMixin, General],
  props: {
    modal: Boolean,
    visitList: {
      type: Array,
      default: () => []
    },
    visit: {
      type: String,
      default: '',
    }
  },
  data,
  validations: FreeValidation,
  methods: {
    toggle,
    submit,
    getAffectedPositions,
  },
  computed: {
    modalTitle,
    fields,
    formatedItems,
    cellTableClasses,
    ...mapState({
      VisitId: state => state.visitas.VisitId,
      EdiFileId: state => state.visitas.EdiFileId,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>

</style>