<template>
  <CModalExtended :title="modalTitle" color="dark" size="lg" class="modal-extended" :close-on-backdrop="false" :show.sync="modalActive">
    <CRow>
      <CCol sm="12">
        <CInput
          :value="getMovName"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          :label="$t('label.movementType')"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CInput
          class="mt-2"
          :value="getEquipmentQty"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          :label="$t('label.equipmentQuantity')"
          addLabelClasses="text-right"
          maxlength="100"
          disabled
        />
        <CSelect
          class="mt-2"
          :label="$t('label.motive')"
          :placeholder="$t('label.select')"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          addLabelClasses="required text-right"
          :disabled="isEmpty(reasons)"
          :options="reasonsOptions"
          :value.sync="$v.cancelInfo.MovStowageReasonId.$model"
          :invalid-feedback="errorMessage($v.cancelInfo.MovStowageReasonId)"
          :is-valid="hasError($v.cancelInfo.MovStowageReasonId)"
        />

        <div class="d-flex mt-2">
          <label class="col-sm-12 col-lg-4 col-xl-4 required text-right mt-2 pr-1">{{$t('label.date')}}</label>
          <CCol sm="12" lg="8" xl="8" class="p-0">
            <vue-datepicker 
              type="datetime"
              header
              :lang="this.$i18n.locale"
              :editable="false"
              :clearable="false"
              format="DD/MM/YYYY HH:mm"
              placeholder="DD/MM/YYYY HH:mm"
              time-title-format="DD/MM/YYYY HH:mm"
              v-model="cancelInfo.TransactionDate"
              :disabled-date="validateDateRange"
              :append-to-body="false"
              :disabled = "false"
              value-type="format"
              :show-second="false"
            >
              <template #icon-calendar>
                  <div style="display: none"></div>
              </template>
            </vue-datepicker>
          </CCol>
        </div>

        <CTextarea
          class="mt-2"
          addLabelClasses="required text-right"
          :label="$t('label.observation')"
          :placeholder="$t('label.observation')"
          :horizontal="{
            label: 'col-sm-12 col-lg-4',
            input: 'col-sm-12 col-lg-8',
          }"
          rows="3"
          maxlength="150"
          v-model.trim="$v.cancelInfo.MovStowageReasonDs.$model"
          :invalid-feedback="errorMessage($v.cancelInfo.MovStowageReasonDs)"
          :is-valid="hasError($v.cancelInfo.MovStowageReasonDs)"
        />
      </CCol>
    </CRow>

    <template #footer>
      <CButton
        color="add"
        shape="square"
        :disabled="isSubmit"
        @click="submit"
      >
        <div v-if="!isSubmit">
          <CIcon name="checkAlt" /><span class="ml-1">{{
            $t("button.accept")
          }}</span>
        </div>
        <div v-if="isSubmit">
          <span
            class="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
          <span class="ml-1">{{ $t("button.accept") }}</span>
        </div>
      </CButton>
      <CButton
        color="wipe"
        shape="square"
        :disabled="isSubmit"
        @click="toggle(false)"
      >
        <CIcon name="x" />&nbsp; {{ $t("button.cancel") }}
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { mapState } from "vuex";
import ENUM from '@/_store/enum';
import {
  DateFormater
} from "@/_helpers/funciones";
import CancelValidation from '@/_validations/ajustes-plano/CancelValidations';
import ModalMixin from "@/_mixins/modal";
import General from "@/_mixins/general";

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    cancelInfo: {
      MovStowageReasonDs: "",
      TransactionDate: "",
      MovStowageReasonId: "",
    },
    reasons: [],
    // Fechas
    searchFilterDate:{
      fromDay:"",
      toDay:"",
    },
    previousDate: new Date(),
    laterDate: new Date(),
    disabledFilterByPreference: false,
  };
}

//Methods
function toggle(newVal) {
  if(newVal){
    this.setData()
    this.getDateRange();
    this.getReasons();
  }

  this.modalActive = newVal;
}
function setData() {
  this.cancelInfo.TransactionDate = this.itinerarySelected && this.itinerarySelected.Atd
        ? DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Atd)
        : DateFormater.formatDateTimeWithSlash(new Date());
}
function resetForm() {
  this.cancelInfo.TransactionDate = '';
  this.cancelInfo.MovStowageReasonDs = '';
  this.cancelInfo.MovStowageReasonId = '';
  this.$v.$reset();
}
function submit() {
  try {
    this.$v.cancelInfo.$touch();
    if (this.$v.cancelInfo.$error) {
      throw this.$t("label.errorsPleaseCheck");
    }

    this.$store.state.visitas.apiStateForm = ENUM.LOADING;
    this.isSubmit = true;
    let route = 'VisitCargoCancelation';
    let CargoJson = this.cancelList.map((item) => Object.assign({}, {
      VisitCargoId: item.VisitCargoId,
      MovStowageId: this.movType ? this.movType.MovStowageId : '',
      MovStowageReasonId: this.cancelInfo.MovStowageReasonId,
      MovStowageReasonDs: this.cancelInfo.MovStowageReasonDs,
      TransactionDate: DateFormater.formatDateTimeWithoutSlash(this.cancelInfo.TransactionDate),
    }));

    let metodo = "POST";

    this.$http
      .ejecutar(metodo, route, CargoJson, {
        root: "CargoJson",
      })
      .then((response) => {
        this.$emit("submited", {
          type: 'cancel',
        });
        this.modalActive = false;
        this.$notify({
          group: "container",
          title: "¡" + this.$t("label.success") + "!",
          text: response.data.data[0].Response,
          type: "success",
        });
        this.resetForm();
      })
      .catch((err) => {
        this.$notify({
          group: "container",
          title: "¡Error!",
          text: err,
          type: "error",
        });
            this.$store.state.visitas.apiStateForm = ENUM.INIT;
      })
      .then(() => {
        this.isSubmit = false;
        //this.$store.state.visitas.apiStateForm = ENUM.INIT;
      });
  } catch (e) {
    this.notifyError({ text: e });
  }
}


function validateDateRange(date) {
  return date < this.previousDate.setHours(0, 0, 0, 0) || date > this.laterDate;
}
function getDateRange(){
    let currentDate = new Date();

    if(this.itinerarySelected.Ata!=undefined){ 
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Ata);
    }

    if(this.itinerarySelected.Ata==undefined){
        this.previousDate = DateFormater.formatDateRange(this.itinerarySelected.Eta);
    }

    if(this.itinerarySelected.Atd!=undefined){  
        this.laterDate = DateFormater.formatDateRange(this.itinerarySelected.Atd);
    }

    if(this.itinerarySelected.Atd==undefined){
        this.laterDate = currentDate;
    }
}
function getReasons() {
  this.$store.state.visitas.apiStateForm = ENUM.LOADING;
  this.$http
    .get("MovStowageReasonCancel-list", {
      MovStowageId: this.movType ? this.movType.MovStowageId : '',
    })
    .then((response) => {
      this.reasons = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.visitas.apiStateForm = ENUM.INIT;
    });
}
function isEmpty(arr) {
  return arr.length == 0;
}

//Computed
function modalTitle() {

  if(this.cancelList.length == 1){
    let code = this.cancelList[0].ContainerCode ? this.cancelList[0].ContainerCode : '';
    return `${this.$t('label.equipmentCancel')}: ${code}`;
  } else {
    return `${this.$t('label.equipmentCancel')}`;
  }
}
function getMovName() {
  if(!this.movType) return '';
  else return this.$i18n.locale == 'en' ? this.movType.MovStowageNameEn : this.movType.MovStowageNameEs
}
function getEquipmentQty() {
  return this.cancelList.length;
}
function reasonsOptions() {
  return this.reasons.map((item) =>
    Object.assign({}, item, {
      label: this.$i18n.locale == 'en' ? item.MovStowageReasonNameEn : item.MovStowageReasonNameEs,
      value: item.MovStowageReasonId,
    })
  );
}

export default {
  name: "cancel-confirm-modal",
  mixins: [ModalMixin, General],
  props: {
    modal: Boolean,
    cancelList: {
      type: Array,
      default: () => []
    },
    movType: {
      type: Object,
      default: null
    },
  },
  validations: CancelValidation,
  data,
  methods: {
    toggle,
    setData,
    resetForm,
    submit,
    validateDateRange,
    getDateRange,
    getReasons,
    isEmpty,
  },
  computed: {
    modalTitle,
    getMovName,
    getEquipmentQty,
    reasonsOptions,
    ...mapState({
      VisitId: state => state.visitas.VisitId,
      EdiFileId: state => state.visitas.EdiFileId,
      itinerarySelected: state => state.visitas.itinerarySelected,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>

</style>