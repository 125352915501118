<template>
  <CModalExtended :title="modalTitle" color="dark" size="lg" :close-on-backdrop="false" :show.sync="modalActive">
    <CRow>
      <CCol sm="12">
        <c-img-extended
          :src="getImg"
          :error-options="{
            width: 500,
            height: 250,
            text: '',
            bgColor: 'lightgray',
          }"
          block
          class="img-container"
        />
      </CCol>
    </CRow>
    <template #footer>
      <CButton shape="square" color="wipe" class="d-flex align-items-center" @click.stop="toggle(false)">
        <CIcon name="x" />
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import CImgExtended from "@/components/CImgExtended";

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
  };
}

//Methods
function toggle(newVal) {

  this.modalActive = newVal;
}

//Computed
function hasInfo() {
  return this.container != null;
}
function modalTitle() {
  if(this.hasInfo){
    let title = '';
    switch (this.listType) {
      case 0:
        title = 'STANDAR';
        break;
      case 1:
        title = 'HIGH CUBE';
        break;
      case 2:
        title = 'OVERSIZE';
        break;
      case 3:
        title = 'REEFER';
        break;
      case 4:
        title = 'IMDG';
        break;
      default:
        title = 'CONTENEDOR';
        break;
    }

    return `${title}: ${this.container.ContainerCode}`;
  } else
    return `CONTENEDOR`;
}
function getImg() {
  if(this.hasInfo)
    return `${this.$store.getters["connection/getBase"]}${
      this.container.Route ? this.container.Route.replace("Public/", "", null, "i") : ""
    }`;
  else 
      return '';
}

export default {
  name: "container-img-modal",
  mixins: [],
  components: {
    CImgExtended,
  },
  props: {
    modal: Boolean,
    container: {
      type: Object,
      default: null
    },
    listType: {
      type: Number,
      default: 0,
    },
  },
  data,
  methods: {
    toggle,
  },
  computed: {
    modalTitle,
    hasInfo,
    getImg,
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>
.img-container{
  max-height: 400px;
  margin: 0 auto;
}
</style>