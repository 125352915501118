<template>
  <CModalExtended
    color="dark"
    size="lg"
    :close-on-backdrop="false"
    :show.sync="modalActive"
  >
    <template #header>
      <h5><CIcon :name='iconoTitulo' size='xl' class="mr-2" /> {{modalTitle}}</h5>
      <CButton class="close" aria-label="Close" @click="modalActive=false">x</CButton>
    </template>
    <CRow class="options">
      <CCol sm="12" class="mb-2">
        <CRow>
          <CCol sm="4">
            <strong>SLOT: {{ slot }}</strong>
          </CCol>
          <CCol sm="8">
            <strong>CONTAINER CODE: {{ containerCode }}</strong>
          </CCol>
        </CRow>
      </CCol>
      <CCol sm="12">
        <dataTableExtended
     class="align-center-row-datatable"
          hover
          sorter
          size="sm"
          column-filter
          :items="formatedAlerts"
          :fields="fields"
          :loading="isLoading"
          :noItemsView="tableText.noItemsViewText"
          :items-per-page="5"
          :active-page="activePage"
          pagination
        >
          <template #loading>
            <loading/>
          </template>
          <template #Nro="{item}">
            <td class="center-item">
              {{ item.Nro }}
            </td>
          </template>
          <template #ColumnName="{item}">
            <td class="center-item">
              {{ item.ColumnName }}
            </td>
          </template>
          <template #ColumnAlert="{ item }">
            <td>
              <div
                class="alerta"
                :class="{
                  'warning': alertType == 'warning' || alertType == 'alerta' ? true : null,
                  'error': alertType == 'error' || alertType == 'errores' ? true : null,
                }"
              >
                <div class="alert-text"><strong>{{ item.ColumnAlert }}</strong></div>
                <img
                  v-if="alertType == 'warning' || alertType == 'alerta'"
                  alt="A"
                  srcset=""
                  class="alerts-icon"
                  src="/img/iconos/alert.svg"
                >
                <img
                  v-else
                  alt="E"
                  srcset=""
                  class="errors-icon"
                  src="/img/iconos/error.svg"
                >
              </div>
            </td>
          </template>
          <template #Motivo="{item}">
            <td class="center-item">
              {{ item.Motivo }}
            </td>
          </template>
          <template #Icono="{ item }">
            <td :class="item.Estilo">
              <CIcon :name="item.Icono" size='xl' />
            </td>
          </template>
        </dataTableExtended>
      </CCol>
    </CRow>
    <template #footer>
      <CButton
        shape="square"
        color="wipe"
        class="d-flex align-items-center"
        @click.stop="toggle(false)"
      >
        <CIcon name="x" />
        <span class="ml-1">{{ $t('button.exit') }}</span>
      </CButton>
    </template>
  </CModalExtended>
</template>

<script>
import { tableTextTranslatedHelpers } from '@/_helpers/funciones';
import { DateFormater } from '@/_helpers/funciones';
import { mapState } from "vuex";

//Data
function data() {
  return {
    isLoading: false,
    modalActive: this.modal,
    alertas: [],
    activePage: 1,
    texto: '',
    iconoTitulo: '',
    stowageTitle: '',
  };
}

//Methods
function toggle(newVal) {
  if(newVal && this.currentPosition) {
    if(!this.visit){
      this.getAlerts();
      this.listarDoc();
    }else{
      if(this.alertType == "alerta" || this.alertType == "errores") {
        if(this.alertType == "alerta")
          this.alertas = this.currentPosition.AlertJson;
        else
          this.alertas = this.currentPosition.ErrorJson;
      } else {
        if(this.alertType == "warning")
          this.alertas = this.currentPosition.CargoJson[0].AlertJson;
        else
          this.alertas = this.currentPosition.CargoJson[0].ErrorJson;
      }
    }
  }

  this.modalActive = newVal;
}
function getAlerts() {
  this.$store.state.planificacionestiba.apiStateForm = 1;
  this.isLoading = true;

  let ruta = this.alertType == "warning"
    ? "StowagePlanningAlert-list-bycargoId"
    : "StowagePlanningError-list-bycargoId";

  this.$http
    .ejecutar("GET", ruta, {
      StowagePlanningCargoId: this.currentPosition
        ? this.currentPosition.CargoJson[0].StowagePlanningCargoId
        : '',
    })
    .then((response) => {
      this.alertas = response.data.data;
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .then(() => {
      this.$store.state.planificacionestiba.apiStateForm = 0;
      this.isLoading = false;
    });
}
async function listarDoc() {
  // this.loadingOverlay = true;
  await this.$http.get('StowagePlanning-by-id', { Filter: 'ALL', StowagePlanningId: this.StowagePlanning })
  .then(response => {
    let listado = [...response.data.data][0];
    this.stowageTitle = listado.VesselName+", ETA "+DateFormater.formatDateTimeWithSlash(listado.Eta)+", ETD "+DateFormater.formatDateTimeWithSlash(listado.Etd);
  }).catch( err => {
    this.$notify({
      group: 'container',
      title: '¡Error!',
      text: err,
      type: "error"
    });
  })
  .finally(() => {
    // this.loadingOverlay = false;
  });
}

//Computed
function modalTitle() {
  if(this.visit && this.itinerarySelected){
    return this.texto+": "+this.itinerarySelected.VesselName+", ETA "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Eta)+", ETD "+DateFormater.formatDateTimeWithSlash(this.itinerarySelected.Etd);
  } else 
    return this.texto+": "+this.stowageTitle;
}
function slot() {
  if(this.visit && (this.alertType == "alerta" || this.alertType == "errores"))
    return this.currentPosition ? this.currentPosition.CodPosition : '';
  else
    return this.currentPosition ? this.currentPosition.CodPositionLabel : '';
}
function containerCode() {
  if(this.visit && (this.alertType == "alerta" || this.alertType == "errores"))
    return this.currentPosition ? this.currentPosition.ContainerCode ? this.currentPosition.ContainerCode:'' : '';
  else
    return this.currentPosition ? this.currentPosition.CargoJson[0].ContainerCode : '';
}
function tableText() {
  return tableTextTranslatedHelpers(this);
}
function fields() {
  return [
    { 
      key: 'Nro', 
      label: '#',
      filter: false,
      _style: 'width:5%; text-align:center;'
    },
    { key: 'ColumnName', label:this.$t('label.field'), _style:'width: 1%; text-align:center;'},
    {
      key: 'ColumnAlert',
      label: this.alertType == "warning" || this.alertType == 'alerta'
        ? this.$i18n.locale == 'en' ? 'ALERT' :'ALERTA' 
        : 'ERROR',
      _style:'width: 25%; text-align:center;'
    },
    {
      key: 'Motivo',
      label: this.$t('label.motive'),
      _style:'text-align:center;'
    },
    { key: 'Icono', label:this.$t('label.correctionControl'), _style: 'width:10%', sorter: false, filter: false, _classes:"text-center" },
  ];
}
function formatedAlerts() {
  let locale = localStorage.getItem('locale');

  let icono='cil-exclamation'; 
  this.texto = this.$t('label.errors');
  let estilo = 'text-center text-danger';

  if(this.alertType == "warning" || this.alertType == "alerta" || this.alertType == "alertas"){
    icono = 'cil-warning';
    this.texto = this.$t('label.alerts');
    estilo = 'text-center text-warning';
  }
  this.iconoTitulo = icono;

  return this.alertas.map((item) => Object.assign({}, item, {
    ColumnAlert: item.ColumnValue ? item.ColumnValue : 'NO VALUE',
    Motivo: locale == 'es'
    ? this.visit ? item.EdiMessageEs : item.EdiMessageSpanish
    : this.visit ? item.EdiMessageEn : item.EdiMessageEnglish,
    Icono: item.FgFix ? 'cil-check' : icono,
    Estilo: item.FgFix ? 'text-center text-success' : estilo,
  }));
}

export default {
  name: "alerts-modal",
  props: {
    modal: Boolean,
    currentPosition: {
      type: Object,
      default: null,
    },
    alertType: {
      type: String,
      default: '',
    },
    visit: {
      type: String,
      default: '',
    }
  },
  data,
  methods: {
    toggle,
    getAlerts,
    listarDoc,
  },
  computed: {
    modalTitle,
    slot,
    containerCode,
    tableText,
    fields,
    formatedAlerts,
    ...mapState({
      StowagePlanning: state => state.planificacionestiba.planificacionId,
      itinerarySelected: state => state.visitas.itinerarySelected,
    }),
  },
  watch: {
    modal: function (val) {
      this.toggle(val);
    },
    modalActive: function (val) {
      this.$emit("update:modal", val);
    },
  },
};
</script>

<style scoped>
.alerta {
  display: flex;
  flex: 1;
  height: 100%;
  padding: 5px 0;
}
.alerta.warning {
  background-color: #ffeb3b;
}
.alerta.error {
  background-color: #f77b71;
}

.alerts-icon,
.errors-icon {
  width: 1rem;
  margin-left: auto;
  margin-right: 8px;
}
.alert-text {
  margin-left: 8px;
}

.center-item {
  text-align: center;
  vertical-align: middle !important;
}

.modal .modal-header button:hover{
	color: #fff;
	text-emphasis-color: #fff;
	background: transparent;
}
</style>